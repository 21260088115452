.extended {
    @media screen and (max-width: 1500px) {
        flex-direction: column;
        justify-content: center;
    }

    @media screen and (min-width: 1500px) {
        flex-direction: row;
    }
}

.restrained {
    @media screen and (max-width: 1300px) {
        flex-direction: column;
        justify-content: center;
    }

    @media screen and (min-width: 1300px) {
        flex-direction: row;
    }
}

.container {
    display: flex;
    gap: 25px;

    .mainWrapper {
        display: flex;
        flex-direction: column;
        width: 600px;

        .noData {
            height: 245.7px;
            display: flex;
            justify-content: center;
            align-content: center;
            font-size: 20px;
            flex-wrap: wrap;
        }

        .headerWrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 16px;

            .title {
                font-size: 20px;
                font-weight: 500;
                padding-left: 24px;
            }

            .filterWrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                white-space: nowrap;
                gap: 8px;
                width: 50%;
                margin-right: 20px;

                .filterItem {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .filterItem > select {
                    border: none;
                    cursor: pointer;
                    color: var(--ion-color-tertiary);
                }
            }
        }
    }
}
