.wrapperTable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.wrapperHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;
}

.wrapperHeader input.searchbar-input {
    text-align: left;
}

.searchBar {
    display: flex;
    width: 300px;
    align-self: flex-end;
    border: 1px solid var(--ion-color-light);
    box-sizing: border-box;
    border-radius: 10px;

    /* Input */

    font-style: italic !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 22px !important;

    /* gray */

    color: var(--ion-color-medium) !important;

    input {
        --box-shadow: none;
        padding-left: 20px !important;
        text-align: left;
    }
    ion-icon {
        margin-left: 240px;
    }
}

.on {
    color: var(--ion-color-tertiary);
    font-size: 24px;
    z-index: 100;
}

.title {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 27px !important;
    color: var(--ion-color-tertiary) !important;

    display: flex;
    //justify-content: center;
}

table {
    text-align: left;
    margin-left: 10px;
    padding: 10px;
    table-layout: fixed;
    border-bottom: 1px solid var(--ion-color-light);

    .headerWrapper {
        tr {
            background-color: #f3f6f9;
        }

        th {
            color: #63768b;
            padding: 5px 30px;
            font-size: 14px;
            height: 43px;
            font-weight: 500;
        }
        th:last-child {
            border-radius: 0 6px 6px 0;
        }
        th:first-child {
            border-radius: 6px 0 0 6px;
        }
    }

    .bodyWrapper {
        td {
            padding: 0px 30px;
            font-size: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .bounceEmailNotUpdated {
            background-color: var(--ion-color-danger-tint) !important;
        }

        .button {
            display: flex;
            gap: 4px;
        }

        tr {
            border-bottom: 1px solid #e4e4e4;
        }
    }
}

.download {
    text-decoration: none;
    color: var(--ion-color-tertiary);
    font-weight: 500;
}

.ionButton {
    --background: white;
    --color: black;
    margin: 0;
}

.paginationButtonArrow {
    font-size: 16px;
    margin: 2px;
}

.paginationButtonText {
    font-size: 11px;
}

.paginationPageNumberSelected {
    color: white;
    background: var(--ion-color-tertiary);
    border: 2px solid var(--ion-color-tertiary);
    border-radius: 20%;
    display: flex;
    align-items: center;
    text-align: center;
}

.paginationPageNumber {
    color: black;
    background: white;
    border: 1px solid #dde1e7;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 30px;
    height: 30px;
}

.paginationWrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.totalElements {
    font-size: 11px;
}

.antet {
    padding: 1rem 3rem;
    background-color: #808080;

    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    gap: 25px;
}

.wrapper2Spans {
    display: flex;
    flex-direction: column;
    gap: 5px;
    span {
        font-size: 15px;
        color: white;
        display: inline-block;
    }
}
