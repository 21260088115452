ion-content.imageCard {
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 10px;
    --padding-bottom: 10px;
}

.wrapper {
    min-height: 100vh;
}

.inner {
    border-radius: 20px;
    border: 1px solid var(--ion-color-light-tint);
    box-shadow: 0 10px 18px -10px var(--ion-color-light-tint);
    overflow: hidden;
    max-width: var(--max-element-size);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px;

        .logo {
            margin-top: 10px;
        }

        .title {
            font-size: 24px;
            font-weight: 400;
            margin: 10px 0;
        }

        p {
            margin: 0 0 10px;
        }
    }

    .picture {
        width: 100%;
        object-fit: cover;
        margin-bottom: -5px;
    }
}
