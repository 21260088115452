.simple {
  ion-label {
    ion-skeleton-text {
      height: 30px;
      margin: 10px 0;
    }
  }
}

.circle {
  border: 1px solid var(--ion-color-light-tint);
  border-radius: 20px;
  width: calc(50% - 10px);

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }

  ion-label {
    ion-skeleton-text {
      margin: 14px auto;

      &:first-child {
        width: 230px;
        height: 230px;
        border-radius: 50%;
      }

      &:not(:first-child) {
        height: 40px;
      }

      &:nth-of-type(3), &:nth-of-type(4) {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}

.list {
  ion-label {
    ion-skeleton-text {
      height: 20px;
      margin: 6px 0;

      &:last-child {
        width: 40%;
      }
    }
  }
}

.error {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--ion-color-danger);

  ion-icon {
    font-size: 26px;
    margin-right: 10px;
  }

  p {
    width: calc(100% - 56px);
  }
}