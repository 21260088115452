.tooltipWrapper {
  position: relative;
  display: none;

  &.show {
    display: block;
  }

  &.bottom {
    .tooltipContent {
      top: 10px;

      &::after {
        transform: rotate(45deg);
        top: -6px;
      }
    }
  }

  &:not(.bottom) {
    .tooltipContent {
      bottom: 0;

      &::after {
        transform: rotate(-135deg);
        bottom: -6px;
      }
    }
  }

  .tooltipContent {
    position: absolute;
    z-index: 500;
    background: white;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 100%;
    border: 1px solid var(--ion-color-tertiary);
    padding: 10px 20px;
    color: var(--ion-color-tertiary);

    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-top: 1px solid var(--ion-color-tertiary);
      border-left: 1px solid var(--ion-color-tertiary);
      background: white;
      position: absolute;
      left: calc(50% - 11px);
    }

    p {
      margin: 0 20px 0 0;
    }

    ul {
      margin: 0;
      padding-left: 20px;
    }

    li {
      color: var(--ion-color-danger);

      &.valid {
        color: var(--ion-color-primary);
      }
    }

    ion-icon {
      font-size: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

}