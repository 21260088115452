.title {
    text-align: left;
    padding-left: 26px;
    padding-top: 25px;
    padding-bottom: 60px;
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    color: #007cef;
}

.cardsWrapper {
    display: flex;
    flex-direction: row;
}

.cardsWrapperMenuExtended {
    display: flex;

    @media screen and (min-width: 1476px) {
        flex-direction: row;
    }

    @media screen and (max-width: 1476px) {
        flex-direction: column;
    }

    background-color: #eff7ff;
}

.cardsWrapperMenuRestraint {
    display: flex;

    @media screen and (min-width: 1366px) {
        flex-direction: row;
    }

    @media screen and (max-width: 1366px) {
        flex-direction: column;
    }

    background-color: #eff7ff;
}

@media screen and (max-width: 1366px) {
    .userInfoWrapperRestrain {
        display: flex;
        flex-direction: column;
        width: 921px;
        height: 234px;
    }
}

@media screen and (min-width: 1366px) {
    .userInfoWrapperRestrain {
        display: flex;
        flex-direction: column;
        width: 295px;
        height: 648px;
    }
}

@media screen and (max-width: 1476px) {
    .userInfoWrapperExtended {
        display: flex;
        flex-direction: column;
        width: 921px;
        height: 234px;
    }
}

@media screen and (min-width: 1476px) {
    .userInfoWrapperExtended {
        display: flex;
        flex-direction: column;
        width: 295px;
        height: 648px;
    }
}

.card {
    border-radius: 35px;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1366px) {
    .cardRestrain {
        border-radius: 35px;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        display: flex;
        flex-direction: row;
    }
    .cardFrequency{
        width: 300px
    }
}

@media screen and (min-width: 1366px) {
    .cardRestrain {
        border-radius: 35px;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        display: flex;
        flex-direction: column;
        height: 648px;
    }
}

@media screen and (max-width: 1476px) {
    .cardExtended {
        border-radius: 35px;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        display: flex;
        flex-direction: row;
    }

    //.cardFrequency{
    //    width: 300px
    //}
}

@media screen and (min-width: 1476px) {
    .cardExtended {
        border-radius: 35px;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        display: flex;
        flex-direction: column;
        height: 648px;
    }
}

.img {
    width: 97px;
    height: 97px;
    border-radius: 50%;
    // margin-top: 20px;
}

.cardTitle {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: black;
}

.cardTitleContract {
    font-size: 13px;
    font-weight: 500;
    color: black;
    //d margin-top: 20px;
}

.cardSubtitle {
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: black;
    padding-left: 18px;
    padding-bottom: 6px;
}

.cardContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    font-size: 14px;
    color: #464e5f;
}

.icon {
    width: 16px;
    height: 16px;
}

.contractSelectCard {
    // width: 90%;
    border-radius: 35px 0px 0px 35px;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);
    margin-right: 2px;
    height: 628px;

    overflow-y:scroll;


}
.contractSelectCard::-webkit-scrollbar {
    width: 4px !important;
}

.contractCard {
    // width: 90%;
    border-radius: 0px 35px 35px 0px;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);
    margin-left: 2px;
    width: 602px;

    .contractDetailWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .headerInfo {
            display: flex;
            flex-direction: row;
            white-space: nowrap;

            .starDateDescription {
                padding-right: 18px;
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                color: black;
            }

            .starDate {
                margin: 0;
                font-size: 13px;
                font-weight: 400;
                color: black;
            }
        }

        .electronicInvoiceWrapper {
            display: flex;
            flex-direction: row;
            gap: 4px;
            font-size: 13px;
            font-weight: 500;
            color: black;
            white-space: nowrap;

            .electronicInvoiceDescription {
                padding-top: 3.5px;
                margin: 0;
            }

            .electronicInvoiceActive {
                padding-top: 3.5px;
                margin: 0;
                color: green;
                white-space: nowrap
            }

            .electronicInvoiceInactive {
                padding-top: 3.5px;
                margin: 0;
                color: red;
            }
        }

        .contractInvoiceWrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding-left: 22px;
            padding-right: 22px;
            padding-top: 32px;

            .invoiceTitle {
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-bottom: 6px;
            }

            .invoiceCardWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #d6d5d5;
                border-radius: 13px;
                width: 263px;
                height: 120px;

                .invoiceContentWrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    padding-left: 14px;
                    padding-right: 14px;
                    padding-top: 15px;

                    .invoiceDescription {
                        font-size: 13px;
                        font-weight: 500;
                        color: black;
                    }

                    .invoiceNumber {
                        font-size: 13px;
                        font-weight: 400;
                        color: rgba(99, 118, 139, 1);
                    }

                    .invoiceValues {
                        font-size: 13px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 1);
                    }
                }
            }
        }

        .preferencesWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            padding-left: 22px;
            padding-right: 22px;
            padding-bottom: 27px;

            .invoiceTitle {
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-bottom: 6px;
            }

            .preferencesCardWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #d6d5d5;
                border-radius: 13px;
                width: 556px;
                height: 136px;
                padding-left: 11px;
                padding-right: 11px;

                .table {
                    width: 98%;
                    height: 100%;
                    margin-bottom: 21px;
                    margin-left: 0;
                    margin-top: 2px;
                    border-bottom: 0;

                    td {
                        border-right: #f6ecec 1px solid;
                        text-align: center;
                        width: 104px;
                    }

                    th {
                        width: 52px;
                    }

                    .tableHeader {
                        font-size: 12px;
                        font-weight: 500;
                        color: black;
                        text-align: center;
                    }

                    .verticalHeader {
                        font-size: 13px;
                        font-weight: 500;
                        color: #63768b;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .profileMainWrapperRestrain {
        display: flex;
        flex-direction: row;
        padding-top: 20px;
        padding-left: 22px;

        .profileName {
            padding-top: 20px;
            padding-bottom: 4px;
        }

        .emailWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 0;
        }
    }
}

@media screen and (min-width: 1366px) {
    .profileMainWrapperRestrain {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        padding-left: 22px;

        .profileName {
            padding-top: 20px;
            padding-bottom: 10px;
        }

        .emailWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 1476px) {
    .profileMainWrapperExtended {
        display: flex;
        flex-direction: row;
        padding-top: 20px;
        padding-left: 22px;

        .profileName {
            padding-top: 20px;
            padding-bottom: 4px;
        }

        .emailWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 0;
        }
    }
}

@media screen and (min-width: 1476px) {
    .profileMainWrapperExtended {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 62px;

        .profileName {
            padding-top: 20px;
            padding-bottom: 10px;
        }

        .emailWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 1476px) {
    .profileWrapperExtended {
        display: flex;
        flex-direction: row;
        gap: 310px;
    }
}

@media screen and (max-width: 1366px) {
    .profileWrapperRestrain {
        display: flex;
        flex-direction: row;
        gap: 310px;
    }
}

.loginFrequencyWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #d6d5d5;
    border-radius: 13px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 141px;
}

.descriptionText {
    color: black;
    font-size: 13px;
    padding-left: 14px;
}

.infoText {
    color: #63768b;
    font-size: 13px;
    padding-right: 14px;
    font-weight: 500;
    // padding-left: 5px;
}

.contractSelectCardWrapper {
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    margin: 0;

    .contractSelected {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 13px;
        width: 271px;
        height: 88px;
        background-color: rgba(0, 124, 239, 0.1);
        cursor: pointer;
    }

    .contractNotSelected {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 13px;
        width: 271px;
        height: 88px;
        border: 1px solid #d6d5d5;
        cursor: pointer;
    }

    .contractName {
        font-size: 13px;
        font-weight: 500;
        color: black;
        padding-left: 14px;
    }

    .contractAddress {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        padding-left: 12px;
    }

    .arrow {
        color: #007cef;
        padding-right: 16px;
    }

    .arrowHidden {
        visibility: hidden;
    }
}

.antet {
    padding: 1rem 2rem;
    background-color: #f3f6f9;
    color: #63768b;

    display: flex;
    justify-content: space-between;

    gap: 30px;
}

.mainWrapperAntet {
    display: flex;
    flex-direction: column;
    font-weight: 550;
    gap: 5px;
    span {
        font-size: 13px;
        color: #63768b;
        display: inline-block;
    }
}

.wrapper2Spans {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-weight: 500;

    span {
        font-size: 13px;
        color: #63768b;
        display: inline-block;
    }
}
