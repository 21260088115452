.ag-h1 {
  /* H1 */

  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 37px !important;
  /* identical to box height */
}
.layout--desktop .ag-h1 {
  font-size: 27px !important;
  line-height: 39px !important;
}

.ag-menu {
  /* Menu */

  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 19px !important;
  /* identical to box height */
}
.layout--desktop .ag-menu {
  font-size: 15px !important;
  line-height: 21px !important;
}

.ag-numbers {
  /* Numbers */

  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 35px !important;
  line-height: 52px !important;
  /* identical to box height */
}
.layout--desktop .ag-numbers {
  font-size: 37px !important;
  line-height: 54px !important;
}

.ag-number-description {
  /* Number description */

  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 13px !important;
  line-height: 19px !important;
  /* identical to box height */
}
.layout--desktop .ag-number-description {
  font-size: 15px !important;
  line-height: 21px !important;
}

.ag-h2 {
  /* H2 */

  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  text-transform: capitalize;
  /* identical to box height */
}
.layout--desktop .ag-h2 {
  font-size: 20px !important;
  line-height: 29px !important;
}

.ag-body-text {
  /* Body text */

  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  /* identical to box height */
}
.layout--desktop .ag-body-text {
  font-size: 17px !important;
  line-height: 24px !important;
}

.ag-cta {
  /* CTA */

  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  /* identical to box height */
}
.layout--desktop .ag-cta {
  font-size: 16px !important;
  line-height: 23px !important;
}

.ag-graph-details {
  /* Graph details */

  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 11px !important;
  line-height: 16px !important;
  /* identical to box height */
}
.layout--desktop .ag-graph-details {
  font-size: 13px !important;
  line-height: 18px !important;
}

.ag-input {
  /* Input */

  font-family: Poppins !important;
  font-style: italic !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  /* identical to box height */
}

.ag-widget-disclaimer {
  /* Widget disclaimer */

  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 9px !important;
  line-height: 13px !important;
  /* identical to box height */
}

.ag-detail {
  /* Detail */

  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 16px !important;
  /* identical to box height */
}
.layout--desktop .ag-detail {
  // font-size: 13px !important;
  // line-height: 18px !important;
}

.ag-push-title {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 15px !important;

  color: #ffffff !important;
}

.ag-push-detail {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 15px !important;

  color: #b2b2b2 !important;
}
