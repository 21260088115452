.passWrapper {
  position: relative;

  .passInput {
    --padding-end: 60px;
    font-style: normal !important;
  }

  ion-button {
    position: absolute;
    right: 18px;
    top: 2px;
    width: auto;
    z-index: 100;
    --background: white;

    ion-icon {
      font-size: 24px;
    }

    &.off {
      color: var(--ion-color-medium);
    }

    &.on {
      color: var(--ion-color-tertiary);
    }
  }

  &.large {
    .passInput {
      height: 63px;
    }

    ion-icon {
      bottom: 20px;
    }
  }
}