.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    color: #5b6875;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eff7ff;
}

.icon {
    font-size: 2rem;
    color: #5b6875;
}

.icon:hover {
    color: var(--ion-color-tertiary);
}
