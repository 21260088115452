.icons::-webkit-scrollbar {
    width: 0.5em; /* Set the width of the scrollbar */
}

.icons::-webkit-scrollbar-track {
    background-color: transparent; /* Set the background color of the track */
}

.icons::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Set the color of the thumb */
    border-radius: 999px; /* Round the edges of the thumb */
}

.icons::-webkit-scrollbar-thumb:hover {
    background-color: #aaa; /* Change the color of the thumb on hover */
}

.icons {
    display: flex;
    flex-direction: column;
    padding: 20px 0.5rem 0.5rem 0.5rem;
    padding-bottom: 50px;
    position: fixed;
    top: 0;
    bottom: 0;
    border-radius: 0 0 0.5rem 0;
    gap: 8px;
    max-width: 238px;
    min-width: 67.5px;
    overflow-y: auto;
    overflow-x: hidden;

    .icon-menu {
        border-radius: 200px;
        padding: 1.2rem;

        .icon {
            pointer-events: none;
        }

        &[color="tertiary"] {
            --background: var(--ion-color-tertiary);
            --background-hover: var(--ion-color-tertiary);
            --background-hover-opacity: 0.2;
            --background-activated: var(--ion-color-tertiary);
            --color: var(--ion-color-light-contrast);
        }

        &:not([color="tertiary"]) {
            --background: white;
            --background-hover: var(--ion-color-light);
            --background-hover-opacity: 0.2;
            --background-activated: var(--ion-color-light);
            --color: var(--ion-color-light-contrast);
        }

        &:hover {
            --background: var(--background-hover);
            --background-activated: var(--background-hover);
        }

        .menu-element {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: auto;
            cursor: pointer;

            .menu-text {
                margin-left: 0.7rem;
                padding-right: 1.5rem;
            }
        }
    }
}

.main-icon {
    padding: 0.5rem 0.5rem 0.3rem 0.3rem;
}



.icon-link {
    padding: 1rem;
    text-decoration: inherit;
    color: inherit;
    display: flex;

    .link-wrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .logo-image {
            width: 8rem;
        }

        p {
            font-size: 1.1rem;
            font-weight: 500;
            color: #0264a5;
            margin-top: 1px;
        }
    }

    .icon {
        font-size: 2rem;
        color: black;
    }

    .icon:hover {
        color: var(--ion-color-tertiary);
    }
}
