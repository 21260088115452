.search {
  position: relative;
  padding: 0;
  .searchBar {
    display: flex;
    width: 300px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box !important;
    border-radius: 10px !important;
    padding-left: 20px;

    /* Input */

    font-style: italic !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    text-align: start;

    /* gray */


    input {
      --box-shadow: none;
    }
  }
  .searchBar:focus {
    outline: none;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.4) !important;
    opacity: 1 !important;
  }

  ion-icon {
    position: absolute;
    display: flex;
    align-items: center;
    top: 25%;
    right: -20px;
    height: 20px;
    width: 80px;
    border-radius: 5px !important;
    color: var(ion-color-light-contrast);
    font-size: 1rem;
  }
}
.searchTerm {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-top: 1rem;
}

.searchTerm:hover {
  color: var(--ion-color-tertiary);
  cursor: pointer;
}

.iconButton{
  --color: white;
  border-radius: 0;
}

@media screen and (max-width: 420px) {
  .search{
    .searchBar{
      width: 100%;
    }
  }
}