.infoBox {
  border: 1px solid transparent;
  border-radius: 10px;
  margin: 10px;
  position: relative;
  padding: 10px 36px 10px 14px;
  font-size: 13px;
  line-height: 1.5;

  &.noMargin {
    margin: 0;
  }

  ion-icon {
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &.primary {
    border-color: var(--ion-color-primary);
    color: var(--ion-color-primary);
  }

  &.secondary {
    border-color: var(--ion-color-secondary);
    color: var(--ion-color-secondary);
  }

  &.tertiary {
    border-color: var(--ion-color-tertiary);
    color: var(--ion-color-tertiary);
  }

  &.medium {
    border-color: var(--ion-color-medium);
    color: var(--ion-color-medium);
  }

  &.warning {
    border-color: var(--ion-color-warning);
    color: var(--ion-color-warning-darker);

    ion-icon {
      color: var(--ion-color-warning);
    }
  }

  &.danger {
    border-color: var(--ion-color-danger);
    color: var(--ion-color-danger);
  }
}
