/* desktop & tablet */

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: stretch;
  margin: 20px 0;
  width: 100vw;
  //background-color: gray;
}


.layoutCard {
  background-color: white;
}


@media only screen
and (max-device-width : 420px)  {
  .main {
    padding: 20px;
    min-width: 10%;
    max-width: 1877.5px;
    margin-bottom: 50px;
  }

  .mainRestraint {
    padding: 20px;
    min-width: 40%;
    max-width: 2048px;
    margin-left: 50px;
    margin-bottom: 50px;
  }


}
@media screen and (max-width: 420px){
  .wrapper {
    display: flex;
    flex-direction: row;
    gap: 0px;
    justify-content: center;
    align-items: stretch;
    margin: 0;
    width: 100vw;
    //background-color: gray;
    --border-radius: 0;
    .main{
      border-radius: 0;
      padding: 20px 5px;
    }
  }
}
@media only screen // small laptop
and (min-device-width : 420px)
and (max-device-width : 768px)  {
  .main {
    padding: 20px;
    min-width: 40%;
    max-width: 1877.5px;
    margin-left: 50px;
    margin-bottom: 50px;
  }

  .mainRestraint {
    padding: 20px;
    min-width: 40%;
    max-width: 2048px;
    margin-left: 220.5px;
    margin-bottom: 50px;
  }
}




@media only screen // small laptop
and (min-device-width : 768px)
and (max-device-width : 1024px)  {
  .main {
    padding: 20px;
    min-width: 40%;
    max-width: 1877.5px;
    margin-left: 50px;
      margin-bottom: 50px;
  }

  .mainRestraint {
    padding: 20px;
    min-width: 40%;
    max-width: 2048px;
    margin-left: 220.5px;
      margin-bottom: 50px;
  }
}

@media only screen // big laptop
and (min-width: 1024px)
and (max-width: 1920px)  {
  .main {
    padding: 20px;
    min-width: 40%;
    max-width: 1877.5px;
    margin-left: 50px;
      margin-bottom: 50px;
  }

  .mainRestraint {
    padding: 20px;
    min-width: 40%;
    max-width: 2048px;
    margin-left: 220.5px;
      margin-bottom: 50px;
  }
}


@media only screen // big monitor
and (min-width: 1920px) {
  .main {
    padding: 20px;
    min-width: 40%;
    max-width: 1571.5px;
      margin-bottom: 50px;
  }

  .mainRestraint {
    padding: 20px;
    min-width: 40%;
    max-width: 1401px;
    margin-left: 170.5px;
      margin-bottom: 50px;
  }
}

