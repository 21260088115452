.feedbackBox {
  border-radius: 10px;
  margin: 5px;
  position: relative;
  padding: 10px 10px 10px 40px;
  font-size: 13px;
  line-height: 1.5;

  &.noMargin {
    margin: 0;
  }

  ion-icon {
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &.success {
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
  }

  &.error {
    background: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
  }
}
