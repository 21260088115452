
.wrapperTable{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.wrapperHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
}

.newsButton{
  --ripple-color: white;
  align-self: flex-start;

}

.wrapperHeader input.searchbar-input {
  text-align: left;
}

.searchBar{
  display: flex;
  width: 300px;
  align-self: flex-end;
  border: 1px solid var(--ion-color-light);
  box-sizing: border-box;
  border-radius: 10px;


  /* Input */

  font-style: italic !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;

  /* gray */

  color: var(--ion-color-medium) !important;

  input{
    --box-shadow: none;
    padding-left: 20px !important;
    text-align: left;
  }
  ion-icon{
    margin-left: 240px;
  }

}

.on {
  color: var(--ion-color-tertiary);
  font-size: 24px;
  z-index: 100;
}

.title{

  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 27px !important;
  color: var(--ion-color-tertiary) !important;

  display: flex;
  //justify-content: center;
}

table {
  text-align: left;
  margin-left: 10px;
  padding: 10px;
  table-layout: fixed;
  border-bottom: 1px solid var(--ion-color-light);

  .headerWrapper {

    tr{
      background-color: #F3F6F9;
    }

    th {
      color: #63768b;
      font-weight: 400;
      padding: 5px 30px;
      font-size: 14px;
      height: 43px;
      font-weight: 500;
    }
    th:last-child{
      border-radius: 0 6px 6px 0;
    }
    th:first-child{
      border-radius: 6px 0 0 6px;
    }
  }

  .bodyWrapper {
    td{
      padding: 20px 30px;
      font-size: 14px;
    }
    tr{
      cursor: pointer;
      border-bottom: 1px solid #E4E4E4;
    }
  }
}


.download{
  text-decoration: none;
  color: var(--ion-color-tertiary);
  font-weight: 500;
}

.ionButton{
  --background: white;
  --color: black;
  margin: 0;
}

.paginationButtonArrow{
  font-size: 16px;
  margin: 2px;
}

.paginationButtonText {
  font-size: 11px;
}

.paginationPageNumberSelected{
  color: white;
  background: var(--ion-color-tertiary);
  border: 2px solid var(--ion-color-tertiary);
  border-radius: 20%;
  display: flex;
  align-items: center;
  text-align: center;
}

.paginationPageNumber{
  color: black;
  background: white;
  border: 1px solid #DDE1E7;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 30px;
  height: 30px;
}

.paginationWrapper{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.totalElements {
  font-size: 11px;
}

.antet{
  width: 100%;
  padding: 1rem 3rem;
  background-color: #4fbbff;
  color: white;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.wrapper2Spans{
  display: flex;
  flex-direction: column;
  gap: 5px;
  span{
    font-size: 15px;
    color: white;
    display: inline-block;
  }
}

ion-checkbox {
  margin-inline-end: 10px;
}

.wrapperContentMessage, .Editor{
  margin: 1rem 0;
  //width: 650px;
}
.wrapperContentMessage:hover, .wrapperDate:hover{
  background-color: #c5c5c5;
  cursor: pointer;
}

.locationAndDateWrapper{
  display: flex;
  justify-content: space-between;
}

.authorAndStatusWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggleWebWidget{
  --background: gray;
  --handle-background: gray;
  &.toggleWebWidget::part(handle){
    color: white !important;
  }
  &.toggle-checked::part(track) {
    height: 10px;
    width: 65px;

    background: #ddd;

    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
  }
}

.inputAntet{
  margin: 0 10px;

}

.inputWrapperCreate{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.channelsWrapper{
  width: 49%;
  padding: 0;
  ion-checkbox{
    margin: 0 10px;
  }
}

.channelsWrapperDetails{

  display: flex;
  justify-content: flex-start;
  padding: 0;

  ion-item{
    border-top: 1px solid rgb(222,222,222);
    border-bottom: 1px solid rgb(222,222,222);

  }
  ion-item:nth-child(5){
      border-right: 1px solid rgb(222,222,222);
      border-radius: 0 10px 10px 0;

  }
  span:nth-child(1){
    ion-item{
      border-left: 1px solid rgb(222,222,222);
      border-radius:  10px 0 0 10px;
    }
  }
}

.wrapperInsideCaption{
display: flex;
  flex-direction: column;
  margin-bottom: 40px;

}
.wrapperTitlePlusSearchbar{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.wrapperTitlePlusSubtitle{
  display: flex;
  flex-direction: column;
}

.wrapperButtonNews{
  display: flex;
  flex-direction: column;
}

.buttonsDetailsWrapper{

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
}

@media screen and (max-width: 420px){

  .title{
    justify-content: center;
  }
  .wrapperInsideCaption{
    margin-bottom: 20px;
    align-items: center;
    gap: 10px;
  }
  .wrapperTitlePlusSubtitle{
    align-items: center;
  }
  .wrapperButtonNews{
    width: 250px;
    align-items: center;

    ion-button{
      width:100%;
      align-self: center;
    }
  }

  table{
    margin: 0 5px;
    .headerWrapper{
      th{
        padding: 5px 5px;

      }
    }
    .bodyWrapper{
      td{
        padding: 5px 5px;
        ion-icon{
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .channelsWrapper{
    width: 100%;
  }

  .channelsWrapperDetails{

    display: block;

    ion-item{
      border-top: 1px solid rgb(222,222,222);
      border-bottom: none;

    }
    ion-item:nth-child(5){
      border-right: none;
      border-radius: 0;

    }
    ion-item:nth-child(5){
      border-bottom: 1px solid rgb(222,222,222);

    }
    span:nth-child(1){
      ion-item{
        border: none;
        border-radius: 0;
      }
    }
  }

  .buttonsDetailsWrapper{
    //flex-direction: column;
  }


}