.page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #f5f5f5;
}

.title {
    font-size: 4rem;
    font-weight: 600;
    margin-bottom: 2rem;
    display: inline-block;
}

.img {
    width: 14rem;
    max-width: 400px;
    margin-top: 1rem;
}