.toastCloseBtn {
  width: 24px;
  height: 40px;
  padding: 15px;
  color: inherit;
}

.toastIcon {
  height: 20px;
  width: 19px;
  padding: 10px;
  color: inherit;
}

.toastIconBg {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
}
