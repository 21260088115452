.forgotPassWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .logo {
    margin-top: 20px;
  }

  .title {
    font-size: 24px;
    font-weight: 400;
  }

  .form {
    width: 100%;

    ion-input {
      height: 63px;
      margin-bottom: 10px;
      z-index: 1 !important;
    }

    .captcha {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
    }
  }
}
