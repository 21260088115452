.title {
    text-align: left;
    padding-left: 26px;
    padding-top: 25px;
    padding-bottom: 40px;
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    color: #007cef;
}

.cardsWrapperMenuExtended {
    display: flex;

    @media screen and (min-width: 1476px) {
        flex-direction: row;
    }

    @media screen and (max-width: 1476px) {
        flex-direction: column;
    }

    background-color: #eff7ff;
}

.cardsWrapperMenuRestraint {
    display: flex;

    @media screen and (min-width: 1300px) {
        flex-direction: row;
    }

    @media screen and (max-width: 1300px) {
        flex-direction: column;
    }

    background-color: #eff7ff;
}

.userInfoWrapper {
    display: flex;
    flex-direction: column;
    width: 295px;
    height: 580px;
}

.card {
    // width: 90%;
    height: 600px;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);
}

.img {
    width: 97px;
    height: 97px;
    border-radius: 50%;
    // margin-top: 20px;
}
.chip {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 6px;
}
.cardTitle {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: black;
}

.cardTitleContract {
    font-size: 13px;
    font-weight: 500;
    color: black;
    //d margin-top: 20px;
}

.cardSubtitle {
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: black;
    padding-left: 14px;
    padding-bottom: 6px;
}

.cardContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    font-size: 14px;
    color: #464e5f;
}

.inputContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-size: 14px;
    color: #464e5f;
}

.icon {
    width: 16px;
    height: 16px;
}

.contractSelectCard {
    // width: 90%;
    border-radius: 0px 35px 35px 0px;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);
    margin-left: 2px;
}

.contractCard {
    // width: 90%;
    border-radius: 35px 0px 0px 35px;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);
    margin-right: 2px;
    width: 602px;

    .contractDetailWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .headerInfo {
            display: flex;
            flex-direction: row;

            .starDateDescription {
                padding-right: 4px;
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                color: black;
            }

            .starDate {
                margin: 0;
                font-size: 13px;
                font-weight: 400;
                color: black;
            }
        }

        .electronicInvoiceWrapper {
            display: flex;
            flex-direction: row;
            gap: 4px;
            font-size: 13px;
            font-weight: 500;
            color: black;
            align-items: center;

            .electronicInvoiceDescription {
                margin: 0;
            }

            .electronicInvoiceActive {
                margin: 0;
                color: green;
            }

            .electronicInvoiceInactive {
                padding-top: 3.5px;
                margin: 0;
                color: red;
            }
        }

        .contractInvoiceWrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding-left: 22px;
            padding-right: 22px;
            padding-top: 32px;

            .invoiceTitle {
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-bottom: 6px;
            }

            .invoiceCardWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #d6d5d5;
                border-radius: 13px;
                width: 263px;
                height: 120px;

                .invoiceContentWrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    padding-left: 14px;
                    padding-right: 14px;
                    padding-top: 15px;

                    .invoiceDescription {
                        font-size: 13px;
                        font-weight: 500;
                        color: black;
                    }

                    .invoiceNumber {
                        font-size: 13px;
                        font-weight: 400;
                        color: rgba(99, 118, 139, 1);
                    }

                    .invoiceValues {
                        font-size: 13px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 1);
                    }
                }
            }
        }

        .preferencesWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            padding-left: 22px;
            padding-right: 22px;
            padding-bottom: 27px;

            .invoiceTitle {
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-bottom: 6px;
            }

            .preferencesCardWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #d6d5d5;
                border-radius: 13px;
                width: 556px;
                height: 136px;
                padding-left: 11px;
                padding-right: 11px;

                .table {
                    width: 98%;
                    height: 100%;
                    margin-bottom: 21px;
                    margin-left: 0;
                    border-bottom: 0;

                    td {
                        border-right: #f6ecec 1px solid;
                        text-align: center;
                        width: 104px;
                    }

                    th {
                        width: 104px;
                    }

                    .tableHeader {
                        font-size: 12px;
                        font-weight: 500;
                        color: black;
                        text-align: center;
                    }

                    .verticalHeader {
                        font-size: 13px;
                        font-weight: 500;
                        color: #63768b;
                    }
                }
            }
        }
    }
}

.invoiceNumber {
    font-size: 13px;
    font-weight: 400;
    color: rgba(99, 118, 139, 1);

    .input {
        height: 20px;
        width: 135px;
        background: white;
    }
}

.contractCardProfileExtended {
    // width: 90%;
    border-radius: 35px 0px 0px 35px;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);
    margin-right: 2px;

    width: 295px;

    @media screen and (max-width: 1476px) {
        width: 921px;
    }
    height: 615px;

    .contractDetailWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .headerInfo {
            display: flex;
            flex-direction: row;

            .starDateDescription {
                padding-right: 18px;
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                color: black;
            }

            .starDate {
                margin: 0;
                font-size: 13px;
                font-weight: 400;
                color: black;
            }
        }

        .electronicInvoiceWrapper {
            display: flex;
            flex-direction: row;
            gap: 4px;
            font-size: 13px;
            font-weight: 500;
            color: black;

            .electronicInvoiceDescription {
                padding-top: 3.5px;
                margin: 0;
            }

            .electronicInvoiceActive {
                padding-top: 3.5px;
                margin: 0;
                color: green;
            }

            .electronicInvoiceInactive {
                padding-top: 3.5px;
                margin: 0;
                color: red;
            }
        }

        .contractInvoiceWrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding-left: 22px;
            padding-right: 22px;
            padding-top: 32px;

            .invoiceTitle {
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-bottom: 6px;
            }

            .invoiceCardWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #d6d5d5;
                border-radius: 13px;
                width: 263px;
                height: 120px;

                .invoiceContentWrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    padding-left: 14px;
                    padding-right: 14px;
                    padding-top: 15px;

                    .invoiceDescription {
                        font-size: 13px;
                        font-weight: 500;
                        color: black;
                    }

                    .invoiceNumber {
                        font-size: 13px;
                        font-weight: 400;
                        color: rgba(99, 118, 139, 1);
                    }

                    .invoiceValues {
                        font-size: 13px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 1);
                    }
                }
            }
        }

        .preferencesWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            padding-left: 22px;
            padding-right: 22px;
            padding-bottom: 27px;

            .invoiceTitle {
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-bottom: 6px;
            }

            .preferencesCardWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #d6d5d5;
                border-radius: 13px;
                width: 556px;
                height: 136px;
                padding-left: 11px;
                padding-right: 11px;

                .table {
                    width: 98%;
                    height: 100%;
                    margin-bottom: 21px;
                    margin-left: 0;
                    border-bottom: 0;

                    td {
                        border-right: #f6ecec 1px solid;
                        text-align: center;
                        width: 104px;
                    }

                    th {
                        width: 104px;
                    }

                    .tableHeader {
                        font-size: 12px;
                        font-weight: 500;
                        color: black;
                        text-align: center;
                    }

                    .verticalHeader {
                        font-size: 13px;
                        font-weight: 500;
                        color: #63768b;
                    }
                }
            }
        }
    }
}
.contractCardProfileRestrain {
    // width: 90%;
    border-radius: 35px 0px 0px 35px;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);
    margin-right: 2px;

    width: 295px;

    @media screen and (max-width: 1300px) {
        width: 921px;
    }
    height: 615px;

    .contractDetailWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .headerInfo {
            display: flex;
            flex-direction: row;

            .starDateDescription {
                padding-right: 18px;
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                color: black;
            }

            .starDate {
                margin: 0;
                font-size: 13px;
                font-weight: 400;
                color: black;
            }
        }

        .electronicInvoiceWrapper {
            display: flex;
            flex-direction: row;
            gap: 4px;
            font-size: 13px;
            font-weight: 500;
            color: black;

            .electronicInvoiceDescription {
                padding-top: 3.5px;
                margin: 0;
            }

            .electronicInvoiceActive {
                padding-top: 3.5px;
                margin: 0;
                color: green;
            }

            .electronicInvoiceInactive {
                padding-top: 3.5px;
                margin: 0;
                color: red;
            }
        }

        .contractInvoiceWrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding-left: 22px;
            padding-right: 22px;
            padding-top: 32px;

            .invoiceTitle {
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-bottom: 6px;
            }

            .invoiceCardWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #d6d5d5;
                border-radius: 13px;
                width: 263px;
                height: 120px;

                .invoiceContentWrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    padding-left: 14px;
                    padding-right: 14px;
                    padding-top: 15px;

                    .invoiceDescription {
                        font-size: 13px;
                        font-weight: 500;
                        color: black;
                    }

                    .invoiceNumber {
                        font-size: 13px;
                        font-weight: 400;
                        color: rgba(99, 118, 139, 1);
                    }

                    .invoiceValues {
                        font-size: 13px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 1);
                    }
                }
            }
        }

        .preferencesWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            padding-left: 22px;
            padding-right: 22px;
            padding-bottom: 27px;

            .invoiceTitle {
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-bottom: 6px;
            }

            .preferencesCardWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #d6d5d5;
                border-radius: 13px;
                width: 556px;
                height: 136px;
                padding-left: 11px;
                padding-right: 11px;

                .table {
                    width: 98%;
                    height: 100%;
                    margin-bottom: 21px;
                    margin-left: 0;
                    border-bottom: 0;

                    td {
                        border-right: #f6ecec 1px solid;
                        text-align: center;
                        width: 104px;
                    }

                    th {
                        width: 104px;
                    }

                    .tableHeader {
                        font-size: 12px;
                        font-weight: 500;
                        color: black;
                        text-align: center;
                    }

                    .verticalHeader {
                        font-size: 13px;
                        font-weight: 500;
                        color: #63768b;
                    }
                }
            }
        }
    }
}
.profileMainWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 62px;

    .profileName {
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .emailWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.loginFrequencyWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #d6d5d5;
    border-radius: 13px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 141px;
}

.descriptionText {
    color: black;
    font-size: 13px;
    padding-left: 14px;
}

.infoText {
    color: #63768b;
    font-size: 13px;
    padding-right: 14px;
    font-weight: 500;
    // padding-left: 5px;
}

.contractSelectCardWrapper {
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 15px;
    margin: 0;

    .contractSelected {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 13px;
        width: 271px;
        height: 88px;
        background-color: rgba(0, 124, 239, 0.1);
        cursor: pointer;
    }

    .contractNotSelected {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 13px;
        width: 271px;
        height: 88px;
        border: 1px solid #d6d5d5;
        cursor: pointer;
    }

    .contractName {
        font-size: 13px;
        font-weight: 500;
        color: black;
        padding-left: 14px;
    }

    .contractAddress {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        padding-left: 12px;
    }

    .arrow {
        color: #007cef;
        padding-right: 16px;
    }

    .arrowHidden {
        visibility: hidden;
    }
}

.table {
    text-align: center;
    padding: 10px;
    margin: 0;
    table-layout: fixed;
    border-bottom: 1px solid var(--ion-color-light);
    height: 100%;
    width: 100%;

    .headerWrapper {
        tr {
            background-color: #f3f6f9;
        }

        th {
            color: #63768b;
            padding: 1px 6px;
            font-size: 12px;
            height: 8.6px;
            font-weight: 500;
        }
        th:last-child {
            border-radius: 0 6px 6px 0;
        }
        th:first-child {
            border-radius: 6px 0 0 6px;
        }
    }

    .bodyWrapper {
        ::-webkit-scrollbar {
            display: none;
        }

        .emailCell {
            line-height: 21px;
            max-height: 48px;
        }

        td {
            padding: 4px 6px;
            font-size: 12px;
            overflow-y: scroll;
            //white-space: nowrap;
            scrollbar-width: auto;
            max-width: 120px;
        }
        tr {
            border-bottom: 1px solid #e4e4e4;
            height: 14px;
            white-space: nowrap;
        }
    }
}

.antet {
    padding: 1rem 2rem;
    background-color: #f3f6f9;
    color: #63768b;
    max-width: 90%;

    display: flex;
    justify-content: center;

    gap: 80px;
}

.mainWrapperAntet {
    display: flex;
    flex-direction: column;
    font-weight: 550;
    gap: 5px;
    span {
        font-size: 13px;
        color: #63768b;
        display: inline-block;
    }
}

.wrapper2Spans {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-weight: 500;

    span {
        font-size: 13px;
        color: #63768b;
        display: inline-block;
    }
}

ion-chip {
    margin-top: 5px;
    padding-top: 6px;

    width: 95%;

    background: transparent !important;
    border: 0.5px solid !important;

    ion-label {
        --padding-start: 0 !important;
        --padding-end: 0 !important;
        font-size: 13px !important;
    }

    ion-icon {
        font-size: 20px;
    }
}
