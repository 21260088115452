.ionButton{
  --background: white;
  --color: black;
  margin: 0;
}

.paginationButtonArrow{
  font-size: 16px;
  margin: 2px;
}

.paginationButtonText {
  font-size: 11px;
}

.paginationPageNumberSelected{
  color: white;
  background: var(--ion-color-tertiary);
  border: 2px solid var(--ion-color-tertiary);
  border-radius: 20%;
  display: flex;
  align-items: center;
  text-align: center;
}

.paginationPageNumber{
  color: black;
  background: white;
  border: 1px solid #DDE1E7;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 30px;
  height: 30px;
}

.paginationWrapper{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.totalElements {
  font-size: 11px;
}

.paginationButtons{
  display: flex;
  margin: 0 auto;
  margin-top: 10px;
}


