.twoFactorForm {
  width: 370px;

  @media screen and (max-width: 400px) {
    width: auto;
  }
}

.twoFactorInputs {
  input {
    width: 100%;
    height: 60px;
    border: 1px solid var(--ion-color-light) !important;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 15px;
    color: var(--ion-color-medium);
    padding: 0 20px;
    outline: none;

    &:focus {
      border-color: var(--ion-color-tertiary) !important;
    }
  }
}


.titleContainer {
  width: 100%;
  text-align: center;
}


.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  .loaderText {
    display: flex;
    flex-direction: column;
  }

}

.pinInput {
  width: 100%;
  margin-top: 5px;
  text-align: center;
}

.linkResend {
  margin: 0 auto;
}

.validityText {
  margin-bottom: 5px;

}

.progressBar {
  width: 60%;
  margin: 0 auto;
}

.loaderWrapper {
  margin: 0 auto;
  width: 60%;
}

.loader-progressBar {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: rgba(var(--ion-color-tertiary-rgb), 0.1);
  overflow: hidden;
}

.loader-progressBar::after {
  content: '';
  box-sizing: border-box;
  width: 0;
  height: 4.8px;
  background: var(--ion-color-tertiary);
  position: absolute;
  top: 0;
  left: 0;
  animation: animFw 120s linear;
  animation-fill-mode: forwards;
}

@keyframes animFw {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}