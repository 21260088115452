.wrapperTable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.on {
    color: var(--ion-color-tertiary);
    font-size: 24px;
    z-index: 100;
}

.title {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 27px !important;
    color: var(--ion-color-tertiary) !important;

    display: flex;
    justify-content: space-around;
}

table {
    text-align: left;
    margin-left: 10px;
    padding: 10px;
    table-layout: fixed;
    border-bottom: 1px solid var(--ion-color-light);

    .headerWrapper {

        tr{
            background-color: #F3F6F9;
        }

        th {
            color: #63768b;
            font-weight: 400;
            padding: 5px 30px;
            font-size: 14px;
            height: 43px;
            font-weight: 500;
        }
        th:last-child{
            border-radius: 0 6px 6px 0;
        }
        th:first-child{
            border-radius: 6px 0 0 6px;
        }
    }

    .bodyWrapper {
        td{
            padding: 15px 30px;
            font-size: 14px;
        }
        tr{
            cursor: pointer;
            border-bottom: 1px solid #E4E4E4;
        }
        div {
            font-size: 15px;
        }

        img {
            width: 50px;
            height: 50px;
            border-radius: 50px;
        }
    }
}

.download {
    text-decoration: none;
    color: var(--ion-color-tertiary);
    font-weight: 500;
}

.ionButton {
    --background: white;
    --color: black;
    margin: 0;
}

.paginationButtonArrow {
    font-size: 16px;
    margin: 2px;
}

.paginationButtonText {
    font-size: 11px;
}

.paginationPageNumberSelected {
    color: white;
    background: var(--ion-color-tertiary);
    border: 2px solid var(--ion-color-tertiary);
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 30px;
    height: 30px;
}

.paginationPageNumber {
    color: black;
    background: white;
    border: 1px solid #dde1e7;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 30px;
    height: 30px;
}

.paginationWrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.totalElements {
    font-size: 11px;
}

a {
    text-decoration: none;
    color: black !important;
}


.badgeContracts{
        --background: rgba(32, 140, 241, 0.23);;
        --color: #208CF1;
        --padding-start: 7px;
        --padding-top: 4px;
        --padding-end: 7px;
        --padding-bottom: 4px;
    border-radius: 23px;

}